import './Footer.module.css';
import React from 'react';

function Footer() {
  return (
    <footer>
        © 2021 by NUS Design Incubation Centre
    </footer>
  );
}

export default Footer;
