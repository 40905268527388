import React, { Component } from "react";
import {db} from '../../firebase/firebaseConfig';
import classes from './Pedagogy.module.css';

class Pedagogy extends Component {
    state = {
        projects: []
    }

    componentDidMount() {
        db.ref('/uploaded-projects').on('value', querySnapShot => {
            let data = querySnapShot.val() ? querySnapShot.val() : {};
            let projectsInDb = {...data};
            let projectsArr = [...projectsInDb.projects];
            projectsArr = projectsArr.filter((project) => {
                return project.type && project.type.indexOf('Pedagogy') !== -1 && !project.archive;
            })
            this.setState({
                projects: projectsArr,
            });
        });        

        // db.ref('/projects').on('value', querySnapShot => {
        //     let data = querySnapShot.val() ? querySnapShot.val() : {};
        //     let projectsInDb = {...data};

        //     let projectIdsArr = Object.keys(projectsInDb);
        //     let projectsArr = [];
            // projectIdsArr.forEach((id) => {
            //     if (projectsInDb[id].type && projectsInDb[id].type.indexOf('Collaboration') !== -1){
            //         projectsArr.push(projectsInDb[id]);
            //     }
            // })
        //     this.setState({
        //         projects: projectsArr,
        //     });
        // });

    }
    
    render() {
          return (
            <div className={this.state.projects.length !== 0 ? classes.Main : classes.MainEmpty}>
                {
                    this.state.projects.length !== 0 ? this.state.projects.map((project, i) => {
                        return (
                            <a className={classes.Project} key={i} href={`/project/${project.tagName}`} rel="noreferrer">
                                <div className={classes.Img} style={{backgroundImage: `url(${project.heroImg})`}}></div>
                                <div className={classes.Text}>
                                    <h1 className={classes.Name}>{project.name}</h1>
                                    <p className={classes.Description}>{project.summary}</p>
                                </div>
                            </a>
                        )
                    }) : (<p className={classes.NoProject}>No project is available</p>)
                }
            </div>
        );
    }
  }
  
  export default Pedagogy;