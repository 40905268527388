export const getImageSize = (file) => {
    return new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
            const image = new Image()
            image.src = e.target.result
            image.onload = () => {
                resolve({
                    w: image.naturalWidth,
                    h: image.naturalHeight
                })
            }
        }
        fileReader.readAsDataURL(file)
    })
}

export const getImageSizeFromUrl = (url) => {
    return new Promise((resolve) => {
        const image = new Image()
        image.src = url
        image.onload = () => {
            resolve({
                w: image.naturalWidth,
                h: image.naturalHeight
            })
        }
    })
}