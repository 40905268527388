import React from "react";
import { Switch, Route } from "react-router";

import SignIn from "./components/SignIn/SignIn";
// import SignUp from "./components/SignUp/SignUp";
import EditMenu from "./components/EditMenu/EditMenu";
import PasswordReset from "./components/PasswordReset/PasswordReset";

import EditProjects from '../EditProjects/EditProjects';
import EditProjectsConfirmation from '../EditProjectsConfirmation/EditProjectsConfirmation';

import EditAboutPage from '../EditAboutPage/EditAboutPage';
import EditAboutConfirmation from '../EditAboutConfirmation/EditAboutConfirmation'

import ReorderProjects from '../ReorderProjects/ReorderProjects';
import ReorderProjectsConfirmation from '../ReorderProjectsConfirmation/ReorderProjectsConfirmation'

import { UserContext } from "../../providers/UserProvider";

function EditPage() {
  const user = React.useContext(UserContext);
  return (
        user ?
        <Switch>
          <Route exact path="/edit/" component={EditMenu} />              
          <Route exact path="/edit/projects" component={EditProjects} />
          <Route exact path="/edit/projects-confirmation" component={EditProjectsConfirmation} />
          <Route exact path="/edit/about-page" component={EditAboutPage} />
          <Route exact path="/edit/about-confirmation" component={EditAboutConfirmation} />
          <Route exact path="/edit/reorder-projects" component={ReorderProjects} />
          <Route exact path="/edit/reorder-projects-confirmation" component={ReorderProjectsConfirmation} />              
        </Switch>
        : 
        <div>
            <Switch>           
              <Route exact path="/edit/password-reset" component={PasswordReset} />
              <Route path="/edit/" component={SignIn} />   
            </Switch>
        </div>
  );
}
export default EditPage;