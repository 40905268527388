import classes from './NavBar.module.css';
import React, { Component } from 'react';
// import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronUp } from '@fortawesome/free-solid-svg-icons';
// import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';

class NavBar extends Component {
    state = {
        aboutTabs: [
            window.location.href.indexOf('#our-centre') !== -1, 
            window.location.href.indexOf('#our-team') !== -1, 
            window.location.href.indexOf('#about-us') !== -1, 
        ]
    }   

    toggleMenuHidden(){
        const nav = document.getElementById('nav');
        nav.classList.toggle(classes.Hidden);
    }

    onClickHandler = (event) => {
        let currTab = event.target.href.split('/about/')[1]
        let updatedAboutTabs = [...this.state.aboutTabs];
        if (currTab === "#our-centre"){
            updatedAboutTabs = [true, false, false];
        } else if (currTab === "#our-team"){
            updatedAboutTabs = [false, true, false];
        } else {
            updatedAboutTabs = [false, false, true];
        }
        this.setState({aboutTabs: updatedAboutTabs});
    }

    render() {
        return (
            <nav className={classes.Hidden} id="nav">
                <FontAwesomeIcon 
                    icon={faBars} 
                    className={classes.menuIcon}
                    onClick={this.toggleMenuHidden}
                />
                <FontAwesomeIcon 
                    icon={faChevronUp} 
                    className={classes.upIcon}
                    onClick={this.toggleMenuHidden}
                />
                <div className={classes.FirstNavDiv}>
                    <a 
                        href="/" className={classes.Logo}
                        onMouseEnter={() => {
                            let logoText = document.getElementById('logo-text');
                            logoText.style.transform = "translateX(0px)";
                            logoText.style.opacity = "100";
                        }}
                        onMouseLeave={() => {
                            let logoText = document.getElementById('logo-text');
                            logoText.style.transform = "translateX(-50px)";
                            logoText.style.opacity = "0"; 
                        }}
                    ></a>
                    <div className={classes.LogoText} id="logo-text"></div>
                </div>
                <div className={classes.OtherNavDivs}>
                    <div className={classes.NavDiv + " " + classes.Projects}>
                        <div className={classes.List}>
                            <h1>PROJECTS</h1>
                            <ul>
                                <li><a 
                                    href="/incubation" 
                                    style={{color: window.location.href.indexOf('/incubation') !== -1 ? "black" : ""}}
                                    >Incubation</a></li>
                                <li><a 
                                    href="/collaboration" 
                                    style={{color: window.location.href.indexOf('/collaboration') !== -1 ? "black" : ""}}
                                    >Collaboration</a></li>
                                <li><a 
                                    href="/research" 
                                    style={{color: window.location.href.indexOf('/research') !== -1 ? "black" : ""}}
                                    >Research</a></li>
                                <li><a 
                                    href="/pedagogy" 
                                    style={{color: window.location.href.indexOf('/pedagogy') !== -1 ? "black" : ""}}
                                    >Pedagogy</a></li>
                                <li><a 
                                    href="/archive" 
                                    style={{color: window.location.href.indexOf('/archive') !== -1 ? "black" : ""}}
                                    >Archive</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className={classes.NavDiv + " " + classes.About}>
                        <div className={classes.List}>
                            <h1>ABOUT</h1>
                            <ul>
                                <li><a 
                                    onClick={this.onClickHandler}
                                    href="/about/#our-centre" 
                                    style={{color: this.state.aboutTabs[0] ? "black" : ""}}
                                    >Our Centre</a></li>
                                <li><a
                                    onClick={this.onClickHandler}                                
                                    href="/about/#our-team" 
                                    style={{color: this.state.aboutTabs[1] ? "black" : ""}}
                                    >Our Team</a></li>
                                <li><a 
                                    onClick={this.onClickHandler}
                                    href="/about/#contact-us" 
                                    style={{color: this.state.aboutTabs[2] ? "black" : ""}}
                                    >Contact Us</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className={classes.NavDiv + " " + classes.FindUs}>
                        <div className={classes.List}>
                            <h1>FIND US</h1>
                            <ul>
                                <li><a href="https://www.facebook.com/dicnus" target="_blank" rel="noreferrer">Facebook</a></li>
                                <li><a href="https://www.instagram.com/dicnus" target="_blank" rel="noreferrer">Instagram</a></li>
                                <li><a href="https://didit.sg/" target="_blank" rel="noreferrer">DID.IT Store</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavBar;