import React, { Component } from "react";
import { db } from '../../firebase/firebaseConfig';
import firebase from 'firebase';
import "./EditAboutPage.module.css";
import classes from "./EditAboutPage.module.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faChevronDown, 
    faChevronUp, 
    // faPlus, 
    faPlusCircle 
} from '@fortawesome/free-solid-svg-icons';

class EditAboutPage extends Component {
    state = {
        isUploading: false,
        uploadedFile: "",
        showPermanentStaff: false,
        showCurrentIncubees: false,
        showPastIncubees: false
    }

    submitHandler = (e) => {
        e.preventDefault();
        // console.log(this.state);

        db.ref('/about').set({
            address: this.state.address ? this.state.address : "",
            currentIncubees: this.state.currentIncubees ? this.state.currentIncubees : [],
            email: this.state.email ? this.state.email : "",
            googleMapURL: this.state.googleMapURL ? this.state.googleMapURL : "",
            ourCentre: this.state.ourCentre ? this.state.ourCentre : "",
            pastIncubees: this.state.pastIncubees ? this.state.pastIncubees : [],
            permanentStaff: this.state.permanentStaff ? this.state.permanentStaff : [],
            photoURL: this.state.photoURL ? this.state.photoURL : ""
        }).then(() => {
            this.setState({
                isUploading: false,
                uploadedFile: "",
                showPermanentStaff: false,
                showCurrentIncubees: false,
                showPastIncubees: false
            });
            window.location.href = '/edit/about-confirmation';
        });
    }

    componentDidMount() {
        db.ref('/about').on('value', querySnapShot => {
            let data = querySnapShot.val() ? querySnapShot.val() : {};
            let aboutDetailsInDb = {...data};

            if (!aboutDetailsInDb.currentIncubees){
                aboutDetailsInDb.currentIncubees = [];
            }
            if (!aboutDetailsInDb.pastIncubees){
                aboutDetailsInDb.pastIncubees = [];
            }
            if (!aboutDetailsInDb.permanentStaff){
                aboutDetailsInDb.permanentStaff = [];
            }

            let updatedPastYears = aboutDetailsInDb.pastYears ? [...aboutDetailsInDb.pastYears] : [];
            aboutDetailsInDb.pastIncubees.forEach((incubee) => {
                if (updatedPastYears.indexOf(incubee.year) === -1){
                    updatedPastYears.push(incubee.year);
                }
            })
            aboutDetailsInDb.pastYears = updatedPastYears;
            this.setState(aboutDetailsInDb);
        });
    }

    // submitHandler = (e) => {
    //     e.preventDefault();

    //     db.ref('/projects').push({
    //         heroImg: this.state.imgsArr[0].url,
    //         name: this.state.name,
    //         type: this.state.type,
    //         tagName: this.state.tagName,
    //         summary: this.state.summary,
    //         designer: this.state.designer,
    //         aboutProject: this.state.aboutProject,
    //         aboutDesigner: this.state.aboutDesigner,

    //         imgsArr: this.state.imgsArr
    //       }).then(() => {
    //         this.setState({
    //             heroImg: "",
    //             name: "",
    //             type: [],
    //             typeOptions: ["Incubation", "Collaboration", "Research"],
    //             tagName: "",
    //             summary: "",
    //             designer: "",
    //             aboutProject: "",
    //             aboutDesigner: "",
    
    //             imgsArr: [],
    //             imgSubmitsArr: [0],
    //         });
    //         window.location.href = '/submit-confirmation';
    //       });
    // }
    
    render() {
          return (
            <form id="entryForm" onSubmit={this.submitHandler}>

                {/* OUR CENTRE */}
                <h1>OUR CENTRE</h1>

                {/* WRITEUP */}
                <h2>Writeup</h2>
                <textarea 
                    name="our-centre" 
                    cols="30" rows="10" 
                    placeholder="Our Centre"
                    value={this.state.ourCentre}
                    onChange={(e) => {this.setState({ourCentre: e.target.value})}}
                ></textarea>

                {/* PHOTO */}
                <h2>Photo</h2>
                <div className={classes.ImageSubmit}>
                    <div
                        className={classes.ImgThumbnail}
                        style={{backgroundImage: `url(${this.state.photoURL})`}}
                    ></div>                    
                    <input 
                        className={classes.UploadImages}
                        type="file"
                        id="images" name="images"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                            let a = Array.from(e.target.files);

                            this.setState({
                                isUploading: true,
                                uploadedFile: a
                            })

                            let updatedPhotoURL = this.state.photoURL;
                            
                            a.forEach((file, i) => {
                                var storageRef = firebase.storage().ref();
                                var ref = storageRef.child('images/' + file.name);
                                    
                                ref.put(file).then((snapshot) => {
                                    storageRef.child('images/' + file.name).getDownloadURL()
                                    .then((url) => {
                                        updatedPhotoURL = url;
                                        this.setState({
                                            photoURL: updatedPhotoURL,
                                            isUploading: false
                                        });
                                    })                              
                                });
                            })
                        }}
                    >
                    </input>
                    <h3 
                        id="upload-status" 
                        style={{display: this.state.uploadedFile.length === 0 ? "none" : "block"}}
                    >
                        {this.state.isUploading ? "Uploading..." : "Uploaded"}
                    </h3>                
                </div>

                {/* OUR TEAM */}
                <h1>OUR TEAM</h1>

                {/* PERMANENT STAFF */}
                {/* Category Header */}
                <div
                    className={classes.Category}
                    onClick = {() => {
                        this.setState({showPermanentStaff: !this.state.showPermanentStaff})
                    }}
                >
                    <h2>Permanent Staff</h2>
                    <FontAwesomeIcon 
                        style={{display: this.state.showPermanentStaff ? "none" : "block"}}
                        icon={faChevronDown} 
                        className={classes.Chevron}
                    />
                    <FontAwesomeIcon 
                        style={{display: this.state.showPermanentStaff ? "block" : "none"}}                    
                        icon={faChevronUp} 
                        className={classes.Chevron}
                    />
                </div>
                {/* Permanent Staff Container */}
                <div style={{display: this.state.showPermanentStaff ? "block" : "none"}}>
                    {/* First Add Button */}
                    <FontAwesomeIcon 
                        style = {{width: "100%", textAlign: "center"}} 
                        icon={faPlusCircle} 
                        className={classes.Plus}
                        onClick={() => {
                            let updatedPermanentStaff = [...this.state.permanentStaff];
                            let newStaff = {
                                name: "",
                                jobTitle: "",
                                description: "",
                                descriptionArr: []
                            };
                            updatedPermanentStaff.unshift(newStaff);
                            this.setState({
                                permanentStaff: updatedPermanentStaff
                            })
                        }}
                    />
                    {/* Permanent Staff List */}  
                    {this.state.permanentStaff ? this.state.permanentStaff.map((staff, i) => {
                        return(
                            <div className={classes.StaffModule} key={staff + i}>
                                {/* Name */}  
                                <input 
                                    type="text" 
                                    placeholder="Name"
                                    value={staff.name}
                                    onChange={(e) => {
                                        let updatedPermanentStaff = [...this.state.permanentStaff];
                                        updatedPermanentStaff[i].name = e.target.value;
                                        this.setState({
                                            permanentStaff: updatedPermanentStaff
                                        })
                                    }}
                                />
                                {/* Job Title */} 
                                <input 
                                    type="text" 
                                    placeholder="Job Title"
                                    value={staff.jobTitle}
                                    onChange={(e) => {
                                        let updatedPermanentStaff = [...this.state.permanentStaff];
                                        updatedPermanentStaff[i].jobTitle = e.target.value;
                                        this.setState({
                                            permanentStaff: updatedPermanentStaff
                                        })
                                    }}
                                />
                                {/* Description */} 
                                <textarea 
                                    name="description" 
                                    cols="30" rows="5"                             
                                    type="text" 
                                    placeholder="Description"
                                    value={staff.description}
                                    onChange={(e) => {
                                        let updatedPermanentStaff = [...this.state.permanentStaff];
                                        updatedPermanentStaff[i].description = e.target.value;
                                        updatedPermanentStaff[i].descriptionArr = e.target.value.split(/(?:\r\n|\r|\n)/);
                                        this.setState({
                                            permanentStaff: updatedPermanentStaff
                                        })
                                    }}
                                ></textarea>
                                {/* Delete Button */} 
                                <div className={classes.Delete}>
                                    <div 
                                        className={classes.DeleteButton}
                                        onClick={() => {
                                            let deleteConfirmation = document.getElementById("permanent-staff-" + i + "-delete");
                                            deleteConfirmation.style.display = "flex"
                                        }}
                                    >DELETE</div>
                                    <div className={classes.DeleteConfirmation} id={"permanent-staff-" + i + "-delete"}>
                                        <p>Are you sure?</p>
                                        <p 
                                            className={classes.DeleteYes}
                                            onClick={() => {
                                                let updatedPermanentStaff = [...this.state.permanentStaff];
                                                updatedPermanentStaff.splice(i, 1);
                                                let deleteConfirmation = document.getElementById("permanent-staff-" + i + "-delete");
                                                deleteConfirmation.style.display = "none"
                                                this.setState({
                                                    permanentStaff: updatedPermanentStaff
                                                })
                                            }}
                                        >YES</p>
                                        <p 
                                            className={classes.DeleteNo}
                                            onClick={() => {
                                                let deleteConfirmation = document.getElementById("permanent-staff-" + i + "-delete");
                                                deleteConfirmation.style.display = "none"
                                            }}
                                        >NO</p> 
                                    </div>
                                </div>
                                {/* Add Button */} 
                                <FontAwesomeIcon 
                                    style = {{width: "100%", textAlign: "center"}} 
                                    icon={faPlusCircle} 
                                    className={classes.Plus}
                                    onClick={() => {
                                        let updatedPermanentStaff = [...this.state.permanentStaff];
                                        let newStaff = {
                                            name: "",
                                            jobTitle: "",
                                            description: "",
                                            descriptionArr: []
                                        };
                                        updatedPermanentStaff.splice(i+1, 0, newStaff);
                                        this.setState({
                                            permanentStaff: updatedPermanentStaff
                                        })
                                    }}
                                />                                                                                                                              
                            </div>

                        )
                    }) : <div></div>}
                </div>

                {/* CURRENT INCUBEES */}
                {/* Category Header */} 
                <div
                    className={classes.Category}
                    onClick = {() => {
                        this.setState({showCurrentIncubees: !this.state.showCurrentIncubees})
                    }}
                >
                    <h2>Current Incubees</h2>
                    <FontAwesomeIcon 
                        style={{display: this.state.showCurrentIncubees ? "none" : "block"}}
                        icon={faChevronDown} 
                        className={classes.Chevron}
                    />
                    <FontAwesomeIcon 
                        style={{display: this.state.showCurrentIncubees ? "block" : "none"}}                    
                        icon={faChevronUp} 
                        className={classes.Chevron}
                    />
                </div>
                {/* Current Incubees Container */} 
                <div style={{display: this.state.showCurrentIncubees ? "block" : "none"}}>
                    {/* First Add Button */}
                    <FontAwesomeIcon 
                        style = {{width: "100%", textAlign: "center"}} 
                        icon={faPlusCircle} 
                        className={classes.Plus}
                        onClick={() => {
                            let updatedCurrentIncubees = [...this.state.currentIncubees];
                            let newIncubee = {
                                name: "",
                                jobTitle: "",
                                description: "",
                                descriptionArr: []
                            };
                            updatedCurrentIncubees.unshift(newIncubee);
                            this.setState({
                                currentIncubees: updatedCurrentIncubees
                            })
                        }}
                    />
                    {/* Current Incubees List */}  
                    {this.state.currentIncubees ? this.state.currentIncubees.map((incubee, i) => {
                        return(
                            <div className={classes.StaffModule} key={incubee + i}>
                                {/* Name */} 
                                <input 
                                    type="text" 
                                    placeholder="Name"
                                    value={incubee.name}
                                    onChange={(e) => {
                                        let updatedCurrentIncubees = [...this.state.currentIncubees];
                                        updatedCurrentIncubees[i].name = e.target.value;
                                        this.setState({
                                            currentIncubees: updatedCurrentIncubees
                                        })
                                    }}
                                />
                                {/* Job Title */} 
                                <input 
                                    type="text" 
                                    placeholder="Job Title"
                                    value={incubee.jobTitle}
                                    onChange={(e) => {
                                        let updatedCurrentIncubees = [...this.state.currentIncubees];
                                        updatedCurrentIncubees[i].jobTitle = e.target.value;
                                        this.setState({
                                            currentIncubees: updatedCurrentIncubees
                                        })
                                    }}
                                />
                                {/* Description */} 
                                <textarea 
                                    name="description" 
                                    cols="30" rows="5"                             
                                    type="text" 
                                    placeholder="Description"
                                    value={incubee.description}
                                    onChange={(e) => {
                                        let updatedCurrentIncubees = [...this.state.currentIncubees];
                                        updatedCurrentIncubees[i].description = e.target.value;
                                        updatedCurrentIncubees[i].descriptionArr = e.target.value.split(/(?:\r\n|\r|\n)/);
                                        this.setState({
                                            currentIncubees: updatedCurrentIncubees
                                        })
                                    }}
                                ></textarea>
                                {/* Delete Button */} 
                                <div className={classes.Delete}>
                                    <div 
                                        className={classes.DeleteButton}
                                        onClick={() => {
                                            let deleteConfirmation = document.getElementById("current-incubees-" + i + "-delete");
                                            deleteConfirmation.style.display = "flex"
                                        }}
                                    >DELETE</div>
                                    <div className={classes.DeleteConfirmation} id={"current-incubees-" + i + "-delete"}>
                                        <p>Are you sure?</p>
                                        <p 
                                            className={classes.DeleteYes}
                                            onClick={() => {
                                                let updatedCurrentIncubees = [...this.state.currentIncubees];
                                                updatedCurrentIncubees.splice(i, 1);
                                                let deleteConfirmation = document.getElementById("current-incubees-" + i + "-delete");
                                                deleteConfirmation.style.display = "none"
                                                this.setState({
                                                    currentIncubees: updatedCurrentIncubees
                                                })
                                            }}
                                        >YES</p>
                                        <p 
                                            className={classes.DeleteNo}
                                            onClick={() => {
                                                let deleteConfirmation = document.getElementById("current-incubees-" + i + "-delete");
                                                deleteConfirmation.style.display = "none"
                                            }}
                                        >NO</p> 
                                    </div>
                                </div>
                                {/* Add Button */} 
                                <FontAwesomeIcon 
                                    style = {{width: "100%", textAlign: "center"}} 
                                    icon={faPlusCircle} 
                                    className={classes.Plus}
                                    onClick={() => {
                                        let updatedCurrentIncubees = [...this.state.currentIncubees];
                                        let newIncubee = {
                                            name: "",
                                            jobTitle: "",
                                            description: "",
                                            descriptionArr: []
                                        };
                                        updatedCurrentIncubees.splice(i+1, 0, newIncubee);
                                        this.setState({
                                            currentIncubees: updatedCurrentIncubees
                                        })
                                    }}
                                />                                                                      
                            </div>

                        )
                    }) : <div></div>}
                </div>

                {/* PAST INCUBEES */}
                {/* Category Header */} 
                <div
                    className={classes.Category}
                    onClick = {() => {
                        this.setState({showPastIncubees: !this.state.showPastIncubees})
                    }}
                >
                    <h2>Past Incubees</h2>
                    <FontAwesomeIcon 
                        style={{display: this.state.showPastIncubees ? "none" : "block"}}
                        icon={faChevronDown} 
                        className={classes.Chevron}
                    />
                    <FontAwesomeIcon 
                        style={{display: this.state.showPastIncubees ? "block" : "none"}}                    
                        icon={faChevronUp} 
                        className={classes.Chevron}
                    />
                </div>
                {/* Past Incubees Container */} 
                <div style={{display: this.state.showPastIncubees ? "block" : "none"}}>
                    {/* First Add Button */}
                    <FontAwesomeIcon 
                        style = {{width: "100%", textAlign: "center"}} 
                        icon={faPlusCircle} 
                        className={classes.Plus}
                        onClick={() => {
                            let updatedPastIncubees = [...this.state.pastIncubees];
                            let newIncubee = {
                                year: "",
                                name: "",
                                jobTitle: "",
                                description: "",
                                descriptionArr: []
                            };
                            updatedPastIncubees.unshift(newIncubee);
                            this.setState({
                                pastIncubees: updatedPastIncubees
                            })
                        }}
                    />
                    {/* Past Incubees List */}
                    {this.state.pastIncubees ? this.state.pastIncubees.map((incubee, i) => {
                        return(
                            <div className={classes.StaffModule} key={incubee + i}>
                                {/* Year */} 
                                <input 
                                    type="text" 
                                    placeholder="Year"
                                    value={incubee.year}
                                    onChange={(e) => {
                                        let updatedPastIncubees = [...this.state.pastIncubees];
                                        updatedPastIncubees[i].year = e.target.value;
                                        this.setState({
                                            pastIncubees: updatedPastIncubees
                                        })
                                    }}
                                />
                                {/* Name */} 
                                <input 
                                    type="text" 
                                    placeholder="Name"
                                    value={incubee.name}
                                    onChange={(e) => {
                                        let updatedPastIncubees = [...this.state.pastIncubees];
                                        updatedPastIncubees[i].name = e.target.value;
                                        this.setState({
                                            pastIncubees: updatedPastIncubees
                                        })
                                    }}
                                />
                                {/* Job Title */} 
                                <input 
                                    type="text" 
                                    placeholder="Job Title"
                                    value={incubee.jobTitle}
                                    onChange={(e) => {
                                        let updatedPastIncubees = [...this.state.pastIncubees];
                                        updatedPastIncubees[i].jobTitle = e.target.value;
                                        this.setState({
                                            pastIncubees: updatedPastIncubees
                                        })
                                    }}
                                />
                                {/* Description */} 
                                <textarea 
                                    name="description" 
                                    cols="30" rows="5"                             
                                    type="text" 
                                    placeholder="Description"
                                    value={incubee.description}
                                    onChange={(e) => {
                                        let updatedPastIncubees = [...this.state.pastIncubees];
                                        updatedPastIncubees[i].description = e.target.value;
                                        updatedPastIncubees[i].descriptionArr = e.target.value.split(/(?:\r\n|\r|\n)/);
                                        this.setState({
                                            pastIncubees: updatedPastIncubees
                                        })
                                    }}
                                ></textarea> 
                                {/* Delete Button */} 
                                <div className={classes.Delete}>
                                    <div 
                                        className={classes.DeleteButton}
                                        onClick={() => {
                                            let deleteConfirmation = document.getElementById("past-incubees-" + i + "-delete");
                                            deleteConfirmation.style.display = "flex"
                                        }}
                                    >DELETE</div>
                                    <div className={classes.DeleteConfirmation} id={"past-incubees-" + i + "-delete"}>
                                        <p>Are you sure?</p>
                                        <p 
                                            className={classes.DeleteYes}
                                            onClick={() => {
                                                let updatedPastIncubees = [...this.state.pastIncubees];
                                                updatedPastIncubees.splice(i, 1);
                                                let deleteConfirmation = document.getElementById("past-incubees-" + i + "-delete");
                                                deleteConfirmation.style.display = "none"
                                                this.setState({
                                                    pastIncubees: updatedPastIncubees
                                                })
                                            }}
                                        >YES</p>
                                        <p 
                                            className={classes.DeleteNo}
                                            onClick={() => {
                                                let deleteConfirmation = document.getElementById("past-incubees-" + i + "-delete");
                                                deleteConfirmation.style.display = "none"
                                            }}
                                        >NO</p> 
                                    </div>
                                </div>
                                {/* Add Button */} 
                                <FontAwesomeIcon 
                                    style = {{width: "100%", textAlign: "center"}} 
                                    icon={faPlusCircle} 
                                    className={classes.Plus}
                                    onClick={() => {
                                        let updatedPastIncubees = [...this.state.pastIncubees];
                                        let newIncubee = {
                                            year: "",
                                            name: "",
                                            jobTitle: "",
                                            description: "",
                                            descriptionArr: []
                                        };
                                        updatedPastIncubees.splice(i+1, 0, newIncubee);
                                        this.setState({
                                            pastIncubees: updatedPastIncubees
                                        })
                                    }}
                                />                                                                       
                            </div>
                        )
                    }) : <div></div>}
                </div>

                {/* CONTACT US */}
                <h1>CONTACT US</h1>

                {/* EMAIL */}
                <h2>Email</h2>
                <input 
                    placeholder="Email"
                    value={this.state.email ? this.state.email : ""}
                    onChange={(e) => {this.setState({email: e.target.value})}}
                />

                {/* ADDRESS */}
                <h2>Address</h2>
                <textarea 
                    name="address" 
                    cols="30" rows="5" 
                    placeholder="Address"
                    value={this.state.address}
                    onChange={(e) => {this.setState({address: e.target.value})}}
                ></textarea>

                {/* GOOGLE MAP HTML */}
                <h2>Google Map Embed HTML</h2>
                <input 
                    placeholder="Google Map Embed HTML"
                    value={this.state.googleMapURL ? this.state.googleMapURL : ""}
                    onChange={(e) => {this.setState({googleMapURL: e.target.value})}}
                />

                {/* SUBMIT BUTTON */}
                <button 
                    type="submit"
                >SUBMIT</button>
            </form>
        );
    }
  }
  
  export default EditAboutPage;