import React, { Component } from "react";
import classes from './About.module.css';
import {db} from '../../firebase/firebaseConfig';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

class About extends Component {
    state = {
    }

    componentDidMount() {
        db.ref('/about').on('value', querySnapShot => {
            let data = querySnapShot.val() ? querySnapShot.val() : {};
            let aboutDetailsInDb = {...data};

            if (!aboutDetailsInDb.currentIncubees){
                aboutDetailsInDb.currentIncubees = [];
            }
            if (!aboutDetailsInDb.pastIncubees){
                aboutDetailsInDb.pastIncubees = [];
            }
            if (!aboutDetailsInDb.permanentStaff){
                aboutDetailsInDb.permanentStaff = [];
            }

            let updatedPastYears = aboutDetailsInDb.pastYears ? [...aboutDetailsInDb.pastYears] : [];
            aboutDetailsInDb.pastIncubees.forEach((incubee) => {
                if (updatedPastYears.indexOf(incubee.year) === -1){
                    updatedPastYears.push(incubee.year);
                }
            })
            aboutDetailsInDb.pastYears = updatedPastYears;
            this.setState(aboutDetailsInDb);
        });
      }

    expandListHandler = (yearId, listId) => {
        let year = document.getElementById(yearId);
        let list = document.getElementById(listId);

        if (list.style.display === "" || list.style.display === "none"){
            list.style.display =  "block";
            window.setTimeout(() => {
                year.classList.toggle(classes.Hidden);
                year.classList.toggle(classes.Shown);
            }, 300);
        } else {
            year.classList.toggle(classes.Hidden);
            year.classList.toggle(classes.Shown);
            window.setTimeout(() => {
                list.style.display =  "none";
            }, 300);
        }
    }

    
    render() {
        if (this.state.ourCentre){
            let pArray = this.state.ourCentre.split(/(?:\r\n|\r|\n)/);
            const ourCentreWriteup = document.getElementById('our-centre-writeup');

            pArray.forEach((p, i) => {
                pArray[i] = `<p>${p}</p>`;
            })
            ourCentreWriteup.innerHTML = pArray.join('');
        }

        if (this.state.address){
            let pArray = this.state.address.split(/(?:\r\n|\r|\n)/);
            const ourLocationDiv = document.getElementById('our-location');

            pArray.forEach((p, i) => {
                pArray[i] = `<p>${p}</p>`;
            })
            ourLocationDiv.innerHTML = pArray.join('');
        }

        return (              
              <div className={classes.Main}>
                <div className={classes.Tag} id="our-centre"></div>  
                <div className={classes.OurCentre}>
                    <div className={classes.Info}>
                        <h1>OUR CENTRE</h1>
                        <div id="our-centre-writeup"></div>
                    </div>
                    <div 
                        className={classes.Image}
                        style={{backgroundImage: `url(${this.state.photoURL})`}}
                    ></div>
                </div>
                <div className={classes.Tag} id="our-team"></div>  
                <div className={classes.OurTeam}>
                    <div className={classes.Info}>
                        <h1>OUR TEAM</h1>
                        <div className={classes.StaffContainer}>
                            <div className={classes.PermanentStaff}>
                                <h2>Permanent Staff</h2>
                                {this.state.permanentStaff ? this.state.permanentStaff.map((staff, i) => {
                                    return (
                                        <div className={classes.Staff} key={i}>
                                            <h3>{staff.name}</h3>
                                            <p className={classes.JobTitle}>{staff.jobTitle}</p>
                                            {
                                                staff.descriptionArr ? staff.descriptionArr.map((el, i) => {
                                                    return (
                                                        <p key={"description" + i}>{el}</p>
                                                    )
                                                }) : ""
                                            }
                                        </div>
                                    )
                                }) : <div></div>}
                                {/*
                                <div className={classes.Staff}>
                                    <h3>Ms. YEO WAN JUN</h3>
                                    <p className={classes.JobTitle}>Business Development Manager</p>
                                </div>
                                <div className={classes.Staff}>
                                    <h3>Ms. RACHEL CHEE</h3>
                                    <p className={classes.JobTitle}>Senior Studio Executive</p>
                                </div>
                                <div className={classes.Staff}>
                                    <h3>MR. COLIN THIAM</h3>
                                    <p className={classes.JobTitle}>Specialist Associate (5 Axis CNC)</p>
                                </div>
                                <div className={classes.Staff}>
                                    <h3>Mr. CHEN CHEE KEONG</h3>
                                    <p className={classes.JobTitle}>Specialist Associate (5 Axis CNC and 3D printing)</p>
                                </div> */}
                            </div>

                            <div className={classes.Incubees}>

                                <div className={classes.CurrentIncubees}>
                                    <h2>Current Incubees</h2>
                                    {this.state.currentIncubees ? this.state.currentIncubees.map((incubee, i) => {
                                        return (
                                            <div className={classes.Staff} key={i}>
                                                <h3>{incubee.name}</h3>
                                                <p className={classes.JobTitle}>{incubee.jobTitle}</p>
                                                <p>{incubee.description}</p>
                                            </div>
                                        )
                                    }) : <div></div>}
                                </div>

                                <div className={classes.PastIncubees}>
                                    <h2>Past Incubees</h2>
                                    {
                                        this.state.pastYears ? this.state.pastYears.map((year, i) => {
                                            return(
                                                <div className={`${classes.Year} ${classes.Hidden}`} id={`year-${year}`} key={year + i}>
                                                    <div 
                                                        className={classes.ExpandButton}
                                                        onClick={() => {
                                                            this.expandListHandler(`year-${year}`, `list-${year}`)
                                                        }}
                                                    >
                                                        <h3>{year}</h3>
                                                        <FontAwesomeIcon 
                                                            icon={faChevronDown} 
                                                            className={classes.Chevron}
                                                        />
                                                    </div>
                                                    <div className={classes.List} id={`list-${year}`}>
                                                        {
                                                            this.state.pastIncubees ? this.state.pastIncubees.map((incubee, i) => {
                                                                return incubee.year === year ? (
                                                                    <div className={classes.Staff} key={incubee + i}>
                                                                        <h3>{incubee.name}</h3>
                                                                        <p className={classes.JobTitle}>{incubee.jobTitle}</p>
                                                                        <p>{incubee.description}</p>
                                                                    </div>
                                                                ) : ""
                                                            }) : <div></div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }) : <div></div>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className={classes.Tag} id="contact-us"></div>                           
                <div className={classes.ContactUs}>
                    <div className={classes.ContactContainer}>
                        <div className={classes.Info}>
                            <h1>CONTACT US</h1>
                            <h2>General Inquiries</h2>
                            <p 
                                className={classes.Email}
                                onClick={() => {
                                    window.open(`mailto:${this.state.email}`);
                                }}
                            >{this.state.email}</p>
                            <br/>
                            <h2>Our Location</h2>
                            <div id="our-location"></div>
                        </div>
                        <iframe 
                            className={classes.Map}
                            title="map"
                            src={this.state.googleMapURL} 
                            width="100%" 
                            allowFullScreen="" 
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </div>
        );
    }
  }
  
  export default About;