import React, { Component } from "react";
import {db} from '../../firebase/firebaseConfig';
import classes from './Project.module.css';

class Project extends Component {
    state = {
        imgsArr: [
            // {url: "https://i.pinimg.com/564x/65/b3/3f/65b33fdbe564c1f012847232fb0f6f8b.jpg", type: "vertical"},
            // {url: "https://i.pinimg.com/564x/65/b3/3f/65b33fdbe564c1f012847232fb0f6f8b.jpg", type: "vertical"},
            // {url: "https://i.pinimg.com/564x/37/7d/84/377d84a5fafca989225326b3aedae9b5.jpg", type: "landscape"},
            // {url: "https://i.pinimg.com/564x/5b/d7/03/5bd703635ada26f04184c376f31d6cc7.jpg", type: "landscape"}
        ]
        // name: "",
        // designer: "",
        // aboutProject: "",
        // aboutDesigner: "",
        // heroImg: "",
        // img1: "",  
        // img2: "",  
        // img3: "",  
        // img4: "",      
    }

    componentDidMount() {
        db.ref('/uploaded-projects').on('value', querySnapShot => {
            let data = querySnapShot.val() ? querySnapShot.val() : {};
            let projectsInDb = {...data};
            let projectsArr = [...projectsInDb.projects];
            let currentTagName = window.location.href.split('/project/')[1];
            projectsArr.forEach((project) => {
                if (project.tagName === currentTagName){
                    this.setState(project);
                }
            })
        });

        // db.ref('/projects').on('value', querySnapShot => {
        //     let data = querySnapShot.val() ? querySnapShot.val() : {};
        //     let projectsInDb = {...data};

        //     let projectIdsArr = Object.keys(projectsInDb);
        //     let projectsArr = [];
        //     projectIdsArr.forEach((id) => {
        //         projectsArr.push(projectsInDb[id]);
        //     })
            // let currentTagName = window.location.href.split('/project/')[1];
            // projectsArr.forEach((project) => {
            //     if (project.tagName === currentTagName){
            //         this.setState(project);
            //     }
            // })
        // });
    }

    bold(text){
        var bold = /\*\*(.*?)\*\*/gm;
        var html = text.replace(bold, '<strong>$1</strong>');            
        return html;
    }

    italic(text){
        var italic = /\_\_(.*?)\_\_/gm;
        var html = text.replace(italic, '<span style="font-style: italic">$1</span>');            
        return html;
    }
    
   
    render() {
        // BUILD ABOUT PROJECT PARAGRAPHS
        //Hyperlink
        if (this.state.aboutProject){
            let pArray = this.state.aboutProject.split(/(?:\r\n|\r|\n)/);
            const aboutProjectDiv = document.getElementById('about-project');

            pArray.forEach((p, i) => {
                var newP = p.replace(/(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)))(">(.*)<\/a>)?/gi, function () {
                return '<a href="' + arguments[2] + '" target="_blank" rel="noreferrer">' + (arguments[7] || arguments[2]) + '</a>'
                });
                pArray[i] = `<p>${newP}</p>`;
            })
            aboutProjectDiv.innerHTML = pArray.join('');
        }
        //Bold
        if (this.state.aboutProject){
            const aboutProjectDiv = document.getElementById('about-project');
            aboutProjectDiv.innerHTML = this.bold(aboutProjectDiv.innerHTML);
        }
        //Italic
        if (this.state.aboutProject){
            const aboutProjectDiv = document.getElementById('about-project');
            aboutProjectDiv.innerHTML = this.italic(aboutProjectDiv.innerHTML);
        }        


        // BUILD ABOUT DESIGNER PARAGRAPHS
        //Hyperlink
        if (this.state.aboutDesigner){
            let pArray = this.state.aboutDesigner.split(/(?:\r\n|\r|\n)/);
            const aboutDesignerDiv = document.getElementById('about-designer');

            pArray.forEach((p, i) => {
                var newP = p.replace(/(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)))(">(.*)<\/a>)?/gi, function () {
                return '<a href="' + arguments[2] + '" target="_blank" rel="noreferrer">' + (arguments[7] || arguments[2]) + '</a>'
                });
                pArray[i] = `<p>${newP}</p>`;
            })
            aboutDesignerDiv.innerHTML = pArray.join('');
        }
        //Bold
        if (this.state.aboutDesigner){
            const aboutDesignerDiv = document.getElementById('about-designer');
            aboutDesignerDiv.innerHTML = this.bold(aboutDesignerDiv.innerHTML);
        } 
        //Italic
        if (this.state.aboutDesigner){
            const aboutDesignerDiv = document.getElementById('about-designer');
            aboutDesignerDiv.innerHTML = this.italic(aboutDesignerDiv.innerHTML);
        }               

        // VIDEO
        let video;
        // Video File
        if (this.state.uploadVidFormat === "file" && this.state.vidArr){
            video = (
                <div className={classes.Video}>
                    {this.state.vidArr.map((vid, i) => {
                        return(
                            <video 
                                width="100%"
                                // height="100%"
                                key={i} 
                                className={classes.Video} 
                                controls
                                controlsList="nodownload"
                            >
                                <source src={vid.url} type="video/mp4"/>
                            </video>
                        ) 
                    })}
                </div>
            )              
        }
        // Video Link
        else if (this.state.uploadVidFormat === "url" && this.state.vidArr){
            video = (
                <div className={classes.Video}>
                    {
                        this.state.vidArr.map((vid, i) => {
                            return(
                                <iframe 
                                    width="100%" 
                                    // height="315" 
                                    src={vid.url}
                                    title="Video Player" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen
                                ></iframe>
                            ) 
                        })
                    }
                </div>
            )
        // No Video
        } else {
            video = "";
        }

        // let imgKeysArr = Object.keys(this.state).filter((key) => key.indexOf('img') !== -1);
        // let imgsArr = [];
        // imgKeysArr.forEach((key) => {
        //     imgsArr.push(this.state[key]);
        // })

        return (
            <div className={classes.Main}>
                <div className={classes.HeroImg} style={{backgroundImage: `url(${this.state.heroImg})`}}></div>
                <div className={classes.Info}>
                    <h1>{this.state.name}</h1>
                    <div id="about-project"></div>
                    <h3 style={{margin: "40px 0px 10px 0px"}}>{this.state.name ? "ABOUT THE DESIGNER(S)" : ""}</h3>
                    <h3 style={{color: "var(--yellow)"}}>{this.state.designer}</h3>
                    <div id="about-designer"></div>
                </div>
                <div className={classes.Images} id="images">
                    {
                        this.state.imgsArr.map((img, i) => {
                            return(
                                i !== 0 ? 
                                <div key={i} className={img.type === "vertical" ? classes.ImgV : classes.ImgL} style={{backgroundImage: `url(${img.url})`}}></div>
                                : null
                            ) 
                        })
                    }
                </div>
                {video}
                
            </div>
        );
    }
  }
  
  export default Project;