import React, { useState } from "react";
import { Link } from "react-router-dom";
import classes from '../../Authentication.module.css';
import {auth} from '../../../../firebase/firebaseConfig';

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);
  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = event => {
    event.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
      })
      .catch(() => {
        setError("Error resetting password");
      });
  };

  return (
    <div className={classes.AuthContainer}>
      <h1>
        Reset your Password
      </h1>
      <div>
        <form action="" className={classes.AuthForm}>
          {emailHasBeenSent && (
            <div>
              An email has been sent to you!
            </div>
          )}
          {error !== null && (
            <div>
              {error}
            </div>
          )}
          <label htmlFor="userEmail">
            Email
          </label>
          <input
            type="email"
            name="userEmail"
            id="userEmail"
            value={email}
            placeholder="Your Email"
            onChange={onChangeHandler}
            className="mb-3 w-full px-1 py-2"
          />
          <button
            className=""
            onClick = {(event) => {sendResetEmail(event)}}
          >
            Send Reset Link
          </button>
        </form>
        <p>
          <Link
          to ="/edit"
          className={classes.AuthLink}
          style={{textAlign: "center"}}
          >
            &larr; Back to Sign In page
          </Link>
        </p>
      </div>
    </div>
  );
};
export default PasswordReset;