import React, {createContext, Component} from 'react';
import {auth} from '../firebase/firebaseConfig';

export const UserContext = createContext(null);

class UserProvider extends Component {
    state = {
      user: null
    };
  
    componentDidMount = () => {
      auth.onAuthStateChanged(userAuth => {
        this.setState({ user: userAuth});
        // console.log(userAuth)
      });
    };

    render() {
      return (
        <UserContext.Provider value={this.state.user}>
          {this.props.children}
        </UserContext.Provider>
      );
    }
  }
  export default UserProvider;