import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import Home from '../../pages/Home/Home';
import Incubation from '../../pages/Incubation/Incubation';
import Collaboration from '../../pages/Collaboration/Collaboration';
import Research from '../../pages/Research/Research';
import Pedagogy from '../../pages/Pedagogy/Pedagogy';
import Archive from '../../pages/Archive/Archive';

import EditPage from '../../pages/EditPage/EditPage';

import Project from '../../pages/Project/Project';

import About from '../../pages/About/About';


import classes from './Content.module.css';

  class Content extends Component {
  
    render() {
          return (<div className={classes.Content}>
            <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/incubation" component={Incubation} />
                <Route exact path="/collaboration" component={Collaboration} />
                <Route exact path="/research" component={Research} />
                <Route exact path="/pedagogy" component={Pedagogy} />
                <Route exact path="/archive" component={Archive} />

                <Route path="/project" component={Project} />

                <Route path="/about" component={About} />

                <Route path="/edit" component={EditPage} />
              </Switch>
            </Router>
          </div>
        );
    }
  }
  
  export default Content;