import './App.css';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Content from './components/Content/Content';
import UserProvider from './providers/UserProvider';

function App() {
  return (
    <UserProvider>
      <div className="App">
        <NavBar/>
        <Content/>
        <Footer/>
      </div>
    </UserProvider>
  );
}

export default App;
