import classes from "./EditMenu.module.css";
import React from "react";
import {auth} from '../../../../firebase/firebaseConfig';

const editMenu = () => {
  return (
    <div className = {classes.Container}>
      <button 
        className = {classes.Button}
        style={{backgroundColor: "var(--yellow)", color: "black"}}
        onClick = {() => {window.location.href='/edit/reorder-projects'}}>
        Reorder Projects
      </button> 
      <button 
        className = {classes.Button}
        style={{backgroundColor: "var(--yellow)", color: "black"}}
        onClick = {() => {window.location.href='/edit/projects'}}>
        Edit Projects
      </button> 
      <button 
        className = {classes.Button}
        style={{backgroundColor: "var(--yellow)", color: "black"}}
        onClick = {() => {window.location.href='/edit/about-page'}}>
        Edit About Page
      </button> 
      <button 
        className = {classes.Button}
        onClick = {(event) => {auth.signOut()}}>
        Sign Out
      </button>      
    </div>
  ) 
};
export default editMenu;