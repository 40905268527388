import React from 'react';
import classes from './EditProjectsConfirmation.module.css';

function editProjectsConfirmation() {
  return (
    <div className={classes.EditProjectsConfirmation}>
        <p>Projects have been updated!</p>
        <button onClick={() => {window.location.href = "/edit/projects"}}>CONTINUE EDITING</button>
        <button onClick={() => {window.location.href = "/edit/"}}>EDIT MENU</button>
        <button onClick={() => {window.location.href = "/"}}>HOME PAGE</button>
    </div>
  );
}

export default editProjectsConfirmation;