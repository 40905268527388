import React, { Component } from "react";
import { db } from '../../firebase/firebaseConfig';
import firebase from 'firebase';
import classes from "./ReorderProjects.module.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faArrowDown,
    faArrowUp,
    faArrowCircleDown, 
    faArrowCircleUp
} from '@fortawesome/free-solid-svg-icons';

class ReorderProjects extends Component {
    state = {
        projects: []
    }

    submitHandler = (e) => {
        e.preventDefault();

        // console.log(this.state.projects);

        db.ref('/uploaded-projects').set({
            projects: this.state.projects
        }).then(() => {
            this.setState({
                projects: []
            });
            window.location.href = '/edit/reorder-projects-confirmation';
        });
    }

    componentDidMount() {
        db.ref('/uploaded-projects').on('value', querySnapShot => {
            let data = querySnapShot.val() ? querySnapShot.val() : {};
            let projectsInDb = {...data};
            if (!projectsInDb.projects){
                projectsInDb.projects = []
            }
            let projectsArr = [...projectsInDb.projects];
            // projectsArr.forEach((p, i) => {
            //     p.show = false;
            //     p.uploadedFiles = [];
            //     p.uploadedVid = [];
            // })
            this.setState({
                projects: projectsArr
            });
        });       
    }

    move(arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
    }

    render() {
        return (
          <div className={classes.ProjectsContainer}>
                {this.state.projects ? this.state.projects.map((project, i) => {
                    return (
                        <div key={"project-" + i}>
                            <div className={classes.Header}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div
                                        className={classes.ProjectImg}
                                        style={{
                                            backgroundImage: `url(${project.heroImg})`
                                        }}
                                    ></div>
                                    <h2>{project.name}</h2>
                                </div>
                                
                                <div className={classes.Arrows} style={{display: "flex"}}>
                                    <FontAwesomeIcon 
                                        icon={faArrowCircleUp} 
                                        // icon={faArrowUp} 
                                        onClick={() => {
                                            let updatedProjectsArr = [...this.state.projects];
                                            this.move(updatedProjectsArr, i, i === 0 ? i : i-1);
                                            this.setState({projects: updatedProjectsArr});
                                        }}
                                    />
                                    <FontAwesomeIcon 
                                        icon={faArrowCircleDown} 
                                        // icon={faArrowDown} 
                                        onClick={() => {
                                            let updatedProjectsArr = [...this.state.projects];
                                            this.move(updatedProjectsArr, i, i === updatedProjectsArr.length - 1 ? i : i+1);
                                            this.setState({projects: updatedProjectsArr});
                                        }}
                                    />   
                                </div>
                            </div>
                        </div>
                    )
                }) : ""}
                <button 
                    className={classes.MainSubmitButton}
                    type="submit"
                    onClick={this.submitHandler}
                >SUBMIT</button>
            </div>
        )
    }
}

export default ReorderProjects;