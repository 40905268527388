import React, { Component } from "react";
import { db } from '../../firebase/firebaseConfig';
import firebase from 'firebase';
import classes from "./EditProjects.module.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    // faPlus, 
    faPlusCircle, 
    faChevronDown, 
    faChevronUp, 
    faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { getImageSize } from '../../utils'

class EditProjects extends Component {
    state = {
        // isLoading: true,
        // isUploading: false,
        // isUploadingVid: false,
        projects: []
    }

    submitHandler = (e) => {
        e.preventDefault();
       
        let updatedProjects = [...this.state.projects];
        updatedProjects.forEach((project) => {
            project.heroImg = project.imgsArr && project.imgsArr[0] ? project.imgsArr[0].url : "";
        })
        this.setState({projects: updatedProjects})

        db.ref('/uploaded-projects').set({
            projects: this.state.projects
        }).then(() => {
            this.setState({
                isUploading: false,
                projects: []
            });
            window.location.href = '/edit/projects-confirmation';
        });
    }

    componentDidMount() {
        db.ref('/uploaded-projects').on('value', querySnapShot => {
            let data = querySnapShot.val() ? querySnapShot.val() : {};
            let projectsInDb = {...data};
            if (!projectsInDb.projects){
                projectsInDb.projects = []
            }
            let projectsArr = [...projectsInDb.projects];
            projectsArr.forEach((p, i) => {
                p.show = false;
                p.uploadedFiles = [];
                p.uploadedVid = [];
            })
            this.setState({
                isLoading: false,
                projects: projectsArr,
            });
        });

        // db.ref('/projects').on('value', querySnapShot => {
        //     let data = querySnapShot.val() ? querySnapShot.val() : {};
        //     let projectsInDb = {...data};

        //     let projectIdsArr = Object.keys(projectsInDb);
        //     let projectsArr = [];
        //     projectIdsArr.forEach((id, i) => {
        //         if (i === projectIdsArr.length-1){
        //             projectsInDb[id].show = true;
        //         } else {
        //             projectsInDb[id].show = false;
        //         }
        //         projectsInDb[id].uploadedFiles = [];
        //         projectsArr.push(projectsInDb[id]);
        //     })
        //     this.setState({
        //         projects: projectsArr,
        //     });
        //     console.log(projectsArr);
        // });
        
    }
    
    render() {
          return (
            <div className={classes.ProjectsContainer}>
                <FontAwesomeIcon
                    style = {{display: this.state.isLoading ? "none" : "block", width: "100%", textAlign: "center"}} 
                    icon={faPlusCircle} 
                    className={classes.Plus}
                    onClick={() => {
                        let updatedProjectsArr = [...this.state.projects];
                        let newProject = {
                            aboutDesigner: "",
                            aboutProject: "",
                            designer: "",
                            heroImg: "",
                            imgsArr: [],
                            vidArr: [],
                            name: "New Project",
                            summary: "",
                            type: [],
                            archive: false,

                            show: true,
                            isUploading: false,
                            isUploadingVid: false,
                            uploadedFiles: [],
                            uploadedVid: [],
                            uploadVidFormat: "file"
                        };
                        updatedProjectsArr.unshift(newProject);
                        console.log(updatedProjectsArr);
                        this.setState({
                            projects: updatedProjectsArr
                        })
                    }}
                />        
                {
                    this.state.projects ? this.state.projects.map((project, i) => {
                        return (
                        <div key={"project-" + i}>
                        <div>
                            <div
                                className={classes.Header}                     
                            >
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div
                                        className={classes.ProjectImg}
                                        style={{
                                            backgroundImage: `url(${project.heroImg})`
                                        }}
                                    ></div>
                                    <h2>{project.name}</h2>
                                </div>
                                
                                <div style={{display: "flex"}}>
                                    <FontAwesomeIcon 
                                        style={{display: project.show ? "none" : "block"}}
                                        icon={faChevronDown} 
                                        className={classes.Chevron}
                                        onClick={() => {
                                            let updatedProjectsArr = [...this.state.projects];
                                            updatedProjectsArr[i].show = !updatedProjectsArr[i].show;
                                            this.setState({projects: updatedProjectsArr});
                                        }}
                                    />
                                    <FontAwesomeIcon 
                                        style={{display: project.show ? "block" : "none"}}
                                        icon={faChevronUp} 
                                        className={classes.Chevron}
                                        onClick={() => {
                                            let updatedProjectsArr = [...this.state.projects];
                                            updatedProjectsArr[i].show = !updatedProjectsArr[i].show;
                                            this.setState({projects: updatedProjectsArr});
                                        }}
                                    />   
                                </div>
                            </div>
                            <form 
                                className={classes.ProjectForm}
                                id="entryForm" 
                                onSubmit={this.submitHandler} 
                                style={{display: project.show ? "block" : "none"}}
                            >

                                <h1>{project.name}</h1>

                                {/* ARCHIVE */}
                                <h2 style={{marginTop: 0}}>ARCHIVE</h2>
                                <div className={classes.TypeSection}>
                                    <div className={classes.TypeOption}>
                                        <input 
                                            type="checkbox" 
                                            id="archive" 
                                            name="archive"
                                            value="archive"
                                            checked={project.archive}
                                            onChange={() => {
                                                let updatedProjectsArr = [...this.state.projects];
                                                updatedProjectsArr[i].archive = !updatedProjectsArr[i].archive;
                                                this.setState({projects: updatedProjectsArr});
                                            }}
                                        />
                                        <label htmlFor="archive">Archive this project</label>
                                    </div>
                                </div>

                                {/* PROJECT TYPE */}
                                <h2>PROJECT TYPE</h2>
                                <div className={classes.TypeSection}>
                                    <div className={classes.TypeOption}>
                                        <input 
                                            type="checkbox" 
                                            id="incubation" 
                                            name="incubation"
                                            value="Incubation"
                                            checked={project.type ? project.type.indexOf("Incubation") !== -1 : false}
                                            onChange={() => {
                                                let updatedProjectsArr = [...this.state.projects];

                                                if (!updatedProjectsArr[i].type){
                                                    updatedProjectsArr[i].type = []
                                                }

                                                if (updatedProjectsArr[i].type.indexOf("Incubation") === -1){
                                                    updatedProjectsArr[i].type.push("Incubation")
                                                } else {
                                                    updatedProjectsArr[i].type = updatedProjectsArr[i].type.filter((el) => {return el !== "Incubation"});
                                                }
                                                this.setState({projects: updatedProjectsArr})
                                            }}
                                        />
                                        <label htmlFor="incubation">Incubation</label>
                                    </div>
                                    <div className={classes.TypeOption}>
                                        <input 
                                            type="checkbox" 
                                            id="collaboration" 
                                            name="collaboration"
                                            value="Collaboration"
                                            checked={project.type ? project.type.indexOf("Collaboration") !== -1 : false}
                                            onChange={() => {
                                                let updatedProjectsArr = [...this.state.projects];

                                                if (!updatedProjectsArr[i].type){
                                                    updatedProjectsArr[i].type = []
                                                }

                                                if (updatedProjectsArr[i].type.indexOf("Collaboration") === -1){
                                                    updatedProjectsArr[i].type.push("Collaboration")
                                                } else {
                                                    updatedProjectsArr[i].type = updatedProjectsArr[i].type.filter((el) => {return el !== "Collaboration"});
                                                }
                                                this.setState({projects: updatedProjectsArr})
                                            }}
                                        />
                                        <label htmlFor="collaboration">Collaboration</label>
                                    </div>
                                    <div className={classes.TypeOption}>
                                        <input 
                                            type="checkbox" 
                                            id="research" 
                                            name="research"
                                            value="Research"
                                            checked={project.type ? project.type.indexOf("Research") !== -1 : false}
                                            onChange={() => {
                                                let updatedProjectsArr = [...this.state.projects];

                                                if (!updatedProjectsArr[i].type){
                                                    updatedProjectsArr[i].type = []
                                                }

                                                if (updatedProjectsArr[i].type.indexOf("Research") === -1){
                                                    updatedProjectsArr[i].type.push("Research")
                                                } else {
                                                    updatedProjectsArr[i].type = updatedProjectsArr[i].type.filter((el) => {return el !== "Research"});
                                                }
                                                this.setState({projects: updatedProjectsArr})
                                            }}
                                        />
                                        <label htmlFor="research">Research</label>
                                    </div>
                                    <div className={classes.TypeOption}>
                                        <input 
                                            type="checkbox" 
                                            id="pedagogy" 
                                            name="pedagogy"
                                            value="pedagogy"
                                            checked={project.type ? project.type.indexOf("Pedagogy") !== -1 : false}
                                            onChange={() => {
                                                let updatedProjectsArr = [...this.state.projects];

                                                if (!updatedProjectsArr[i].type){
                                                    updatedProjectsArr[i].type = []
                                                }

                                                if (updatedProjectsArr[i].type.indexOf("Pedagogy") === -1){
                                                    updatedProjectsArr[i].type.push("Pedagogy")
                                                } else {
                                                    updatedProjectsArr[i].type = updatedProjectsArr[i].type.filter((el) => {return el !== "Pedagogy"});
                                                }
                                                this.setState({projects: updatedProjectsArr})
                                            }}
                                        />
                                        <label htmlFor="pedagogy">Pedagogy</label>
                                    </div>
                                </div>

                                {/* PROJECT DETAILS */}
                                <h2>DETAILS</h2>

                                {/* NAME */}
                                <label>Project Name</label>
                                <input 
                                    type="text" 
                                    placeholder="Project Name"
                                    value={project.name}
                                    onChange={(e) => {
                                        let updatedProjectsArr = [...this.state.projects];
                                        let generatedTagName = e.target.value.toLowerCase()
                                        .replace(/[^a-zA-Z0-9 &]/g, "")
                                        .replaceAll(' ', '-')
                                        .replaceAll('&', 'n');
                                        updatedProjectsArr[i].name = e.target.value;
                                        updatedProjectsArr[i].tagName = generatedTagName
                                        this.setState({projects: updatedProjectsArr})
                                    }}
                                />

                                {/* DESIGNER */}
                                <label>Designer</label>
                                <input 
                                    type="text" 
                                    placeholder="Designer"
                                    value={project.designer}
                                    onChange={(e) => {
                                        let updatedProjectsArr = [...this.state.projects];
                                        updatedProjectsArr[i].designer = e.target.value;
                                        this.setState({projects: updatedProjectsArr})
                                    }}
                                />

                                {/* SUMMARY */}
                                <label>Summary</label>
                                <textarea 
                                    name="summary" 
                                    cols="30" rows="5" 
                                    placeholder="Summary"
                                    value={project.summary}
                                    onChange={(e) => {
                                        let updatedProjectsArr = [...this.state.projects];
                                        updatedProjectsArr[i].summary = e.target.value;
                                        this.setState({projects: updatedProjectsArr})
                                    }}
                                ></textarea>

                                {/* ABOUT PROJECT */}
                                <label>About Project</label>
                                <textarea 
                                    name="about-project" 
                                    cols="30" rows="10" 
                                    placeholder="About Project"
                                    value={project.aboutProject}
                                    onChange={(e) => {
                                        let updatedProjectsArr = [...this.state.projects];
                                        updatedProjectsArr[i].aboutProject = e.target.value;
                                        this.setState({projects: updatedProjectsArr})
                                    }}
                                ></textarea>

                                {/* ABOUT DESIGNER */}
                                <label>About Designer</label>
                                <textarea 
                                    name="about-designer" 
                                    cols="30" rows="10" 
                                    placeholder="About Designer"
                                    value={project.aboutDesigner}
                                    onChange={(e) => {
                                        let updatedProjectsArr = [...this.state.projects];
                                        updatedProjectsArr[i].aboutDesigner = e.target.value;
                                        this.setState({projects: updatedProjectsArr})
                                    }}
                                ></textarea>

                                {/* IMAGES */}
                                <h2>IMAGES</h2>
                                {/* Image Thumbnails */}
                                <div 
                                    className={classes.Thumbnails} 
                                    style={{display: !project.imgsArr || project.imgsArr.length === 0 ? "none" : "flex"}}
                                >
                                {
                                    project.imgsArr ? project.imgsArr.map((img, index) => {
                                        return (
                                            <div key={"thumbnail-" + i + index} className={classes.ImgSummary}>
                                                <div
                                                    className={classes.ImgThumbnail}
                                                    style={{backgroundImage: `url(${img.url})`}}
                                                >
                                                    <FontAwesomeIcon 
                                                        // style={{display: project.show ? "none" : "block"}}
                                                        icon={faTrashAlt} 
                                                        className={classes.Trash}
                                                        onClick={() => {
                                                            let updatedProjectsArr = [...this.state.projects];
                                                            updatedProjectsArr[i].imgsArr.splice(index, 1);
                                                            this.setState({projects: updatedProjectsArr});
                                                        }}
                                                    />
                                                </div>
                                                <p className={classes.ImgName}>{img.name ? img.name : `${project.tagName}-${index}`}</p>
                                            </div> 
                                        )
                                    }) : ""
                                }
                                </div>
                                {/* Image Upload */}
                                <div className={classes.ImageSubmit}>
                                    <input 
                                        className={classes.UploadImages}
                                        multiple
                                        type="file"
                                        id="images" name="images"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => {
                                            function compare_name(a, b){
                                                if(a.name < b.name){
                                                        return -1;
                                                }else if(a.name > b.name){
                                                        return 1;
                                                }else{
                                                        return 0;
                                                }
                                            }
                                            let a = Array.from(e.target.files).sort(compare_name);

                                            let updatedProjectsArr = [...this.state.projects];
                                            updatedProjectsArr[i].isUploading = true;
                                            updatedProjectsArr[i].uploadedFiles = a;
                                            this.setState({projects: updatedProjectsArr})

                                            a.forEach(async (file, fileIndex) => {
                                                var storageRef = firebase.storage().ref();
                                                    var ref = storageRef.child('images/' + file.name);
                                                    const { w, h } = await getImageSize(file);
                                                    ref.put(file).then((snapshot) => {
                                                        let imgObj = {};
                                                        storageRef.child('images/' + file.name).getDownloadURL()
                                                        .then((url) => {
                                                            imgObj.url = url;
                                                            imgObj.name = file.name;
                                                            imgObj.type = w > h ? "landscape" : "vertical";

                                                            if (!updatedProjectsArr[i].imgsArr){
                                                                updatedProjectsArr[i].imgsArr = [];
                                                            }

                                                            updatedProjectsArr[i].imgsArr.push(imgObj);
                                                            updatedProjectsArr[i].imgsArr = updatedProjectsArr[i].imgsArr.sort(compare_name);
                                                            updatedProjectsArr[i].isUploading = false;
                                                            // updatedProjectsArr[i].heroImg = updatedProjectsArr[i].imgsArr[0].url;

                                                            this.setState({projects: updatedProjectsArr})
                                                        })
                                                    })
                                            })
                                        }}
                                    >
                                    </input>
                                    {/* Uploaded Images */}
                                    <div id="file-list">
                                        <h3 
                                            id="upload-status" 
                                            style={{display: project.uploadedFiles.length === 0 ? "none" : "block"}}
                                        >
                                            {project.isUploading ? "Uploading..." : "Uploaded"}
                                        </h3>
                                    </div>
                                </div>

                                {/* VIDEO */}
                                <h2>VIDEO</h2>

                                {/* Video Format */}
                                {project.uploadVidFormat === "file" ? (
                                    <select 
                                        className={classes.VidFormat} 
                                        name="format" 
                                        id="format"
                                        value={project.uploadVidFormat}
                                        onChange={(e) => {
                                            let updatedProjectsArr = [...this.state.projects];
                                            updatedProjectsArr[i].uploadVidFormat = e.target.value;
                                            updatedProjectsArr[i].uploadedVid = [];
                                            if (e.target.value === "url"){
                                                updatedProjectsArr[i].vidArr = [
                                                    {url: ""}
                                                ]
                                            } else {
                                                updatedProjectsArr[i].vidArr = []
                                            }
                                            this.setState({projects: updatedProjectsArr});
                                            console.log(project.vidArr);
                                        }}
                                    >
                                        <option value="file">File</option>
                                        <option value="url">URL</option>
                                    </select>) : (
                                        <select 
                                            className={classes.VidFormat} 
                                            name="format" 
                                            id="format"
                                            value={project.uploadVidFormat}
                                            onChange={(e) => {
                                                let updatedProjectsArr = [...this.state.projects];
                                                updatedProjectsArr[i].uploadVidFormat = e.target.value;
                                                updatedProjectsArr[i].uploadedVid = [];
                                                if (e.target.value === "url"){
                                                    updatedProjectsArr[i].vidArr = [
                                                        {url: ""}
                                                    ]
                                                } else if (e.target.value === "file") {
                                                    updatedProjectsArr[i].vidArr = []
                                                }
                                                this.setState({projects: updatedProjectsArr});
                                                console.log(project.vidArr)
                                            }}
                                        >
                                            <option value="url">URL</option>
                                            <option value="file">File</option>
                                        </select>
                                    )
                                }

                                {/* Video Name */}
                                {project.uploadVidFormat === "file" ? (
                                    <div className={classes.Thumbnails} style={{display: !project.vidArr || project.vidArr.length === 0 ? "none" : "block"}}>
                                    {
                                        project.vidArr ? project.vidArr.map((vid, index) => {
                                            return (
                                                <div key={"thumbnail-" + i + index} style={{display: "flex", alignItems: "center"}}>
                                                    <p className={classes.VidName}>
                                                        {vid.name ? vid.name : `${project.tagName}-${vid}`}
                                                    </p>
                                                    <FontAwesomeIcon 
                                                        icon={faTrashAlt} 
                                                        className={classes.TrashVid}
                                                        onClick={() => {
                                                            let updatedProjectsArr = [...this.state.projects];
                                                            updatedProjectsArr[i].vidArr = [];
                                                            this.setState({projects: updatedProjectsArr});
                                                        }}
                                                    />                                                
                                                </div>
                                            )
                                        }) : ""
                                    }
                                    </div>
                                ) : ""}

                                {/* Upload Video */}
                                {
                                    project.uploadVidFormat === "file" ? (
                                        <div className={classes.VideoSubmit}>
                                            <input 
                                                className={classes.UploadVideo}
                                                type="file"
                                                id="video" name="video"
                                                accept="video/mp4"
                                                value=""
                                                onChange={(e) => {
                                                    let a = Array.from(e.target.files);
        
                                                    let updatedProjectsArr = [...this.state.projects];
                                                    updatedProjectsArr[i].isUploadingVid = true;
                                                    updatedProjectsArr[i].uploadedVid = a;
                                                    this.setState({projects: updatedProjectsArr})
        
                                                    a.forEach((file, fileIndex) => {
                                                        var storageRef = firebase.storage().ref();
                                                            var ref = storageRef.child('videos/' + file.name);
                                                            ref.put(file).then((snapshot) => {
                                                                let vidObj = {};
                                                                storageRef.child('videos/' + file.name).getDownloadURL()
                                                                .then((url) => {
                                                                    vidObj.url = url;
                                                                    vidObj.name = file.name;
        
                                                                    if (!updatedProjectsArr[i].vidArr){
                                                                        updatedProjectsArr[i].vidArr = [];
                                                                    }
        
                                                                    updatedProjectsArr[i].vidArr[0] = (vidObj);
                                                                    updatedProjectsArr[i].isUploadingVid = false;
        
                                                                    this.setState({projects: updatedProjectsArr})
                                                                })
                                                            })
                                                    })
                                                }}
                                            >
                                            </input>
                                            {/* Uploaded Video */}
                                            <div id="file-list">
                                                <h3 
                                                    id="upload-status" 
                                                    style={{display: project.uploadedVid.length === 0 ? "none" : "block"}}
                                                >
                                                    {project.isUploadingVid ? "Uploading..." : "Uploaded"}
                                                </h3>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{marginBottom: "20px"}}>
                                            <input 
                                                type="text" 
                                                placeholder="Video Embed Source"
                                                value={project.vidArr[0].url}
                                                onChange={(e) => {
                                                    let updatedProjectsArr = [...this.state.projects];

                                                    updatedProjectsArr[i].vidArr[0].url = e.target.value;

                                                    this.setState({projects: updatedProjectsArr})
                                                }}
                                            />                                                                                        
                                        </div>
                                    ) 
                                }

                                <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "left"}}>
                                    {/* SUBMIT BUTTON */}
                                    <button 
                                        className={classes.SubmitButton}
                                        type="submit"
                                    >SUBMIT</button>
                                    {/* DELETE BUTTON */}
                                    <div className={classes.Delete}>
                                        <div 
                                            className={classes.DeleteButton}
                                            onClick={() => {
                                                let deleteConfirmation = document.getElementById("project-" + i + "-delete");
                                                deleteConfirmation.style.display = "flex"
                                            }}
                                        >DELETE</div>
                                        {/* Delete Confirmation */}
                                        <div className={classes.DeleteConfirmation} id={"project-" + i + "-delete"}>
                                            <p>Are you sure?</p>
                                            <p 
                                                className={classes.DeleteYes}
                                                onClick={() => {
                                                    let updatedProjectsArr = [...this.state.projects];
                                                    updatedProjectsArr.splice(i, 1);
                                                    this.setState({projects: updatedProjectsArr})

                                                    let deleteConfirmation = document.getElementById("project-" + i + "-delete");
                                                    deleteConfirmation.style.display = "none"

                                                    this.setState({
                                                        projects: updatedProjectsArr
                                                    })
                                                }}
                                            >YES</p>
                                            <p 
                                                className={classes.DeleteNo}
                                                onClick={() => {
                                                    let deleteConfirmation = document.getElementById("project-" + i + "-delete");
                                                    deleteConfirmation.style.display = "none"
                                                }}
                                            >NO</p> 
                                        </div>
                                    </div>
                                </div>                          
                            </form> 
                        </div>
                        <FontAwesomeIcon
                            style = {{display: this.state.isLoading ? "none" : "block", width: "100%", textAlign: "center"}} 
                            icon={faPlusCircle} 
                            className={classes.Plus}
                            onClick={() => {
                                let updatedProjectsArr = [...this.state.projects];
                                let newProject = {
                                    aboutDesigner: "",
                                    aboutProject: "",
                                    designer: "",
                                    heroImg: "",
                                    imgsArr: [],
                                    vidArr: [],
                                    name: "New Project",
                                    summary: "",
                                    type: [],
                                    archive: false,

                                    show: true,
                                    isUploading: false,
                                    isUploadingVid: false,
                                    uploadedFiles: [],
                                    uploadedVid: [],
                                    uploadVidFormat: "file"
                                };
                                updatedProjectsArr.splice(i + 1, 0, newProject);
                                console.log(updatedProjectsArr);
                                this.setState({
                                    projects: updatedProjectsArr
                                })
                            }}
                        />                           
                        </div>
                        )
                    }) : ""
                }
                <button 
                    className={classes.MainSubmitButton}
                    type="submit"
                    onClick={this.submitHandler}
                >SUBMIT</button>
            </div>
        );
    }
  }
  
  export default EditProjects;