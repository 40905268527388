import React from 'react';
import classes from './EditAboutConfirmation.module.css';

function editAboutConfirmation() {
  return (
    <div className={classes.EditAboutConfirmation}>
        <p>The About page has been updated!</p>
        <button onClick={() => {window.location.href = "/edit/about-page"}}>CONTINUE EDITING</button>
        <button onClick={() => {window.location.href = "/edit/"}}>EDIT MENU</button>
        <button onClick={() => {window.location.href = "/about"}}>ABOUT PAGE</button>
    </div>
  );
}

export default editAboutConfirmation;