import classes from "../../Authentication.module.css";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {auth} from '../../../../firebase/firebaseConfig';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event, email, password) => {
      event.preventDefault();


      // auth.setPersistence({persistence: auth.Auth.Persistence})
      //   .then(() => {
      //     return auth.signInWithEmailAndPassword(email, password);
      //   })
      //   .catch((error) => {
      //     console.log(error.code);
      //     console.log(error.message);
      //   });

      auth.setPersistence("session")
        .then(() => {
          auth.signInWithEmailAndPassword(email, password).catch(error => {
            setError("Incorrect email/password");
            console.error("Error signing in with password and email", error);
          });
        })
        .catch((error) => {
          console.log(error.code);
          console.log(error.message);
        });
    };

      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;

          if(name === 'userEmail') {
              setEmail(value);
          }
          else if(name === 'userPassword'){
            setPassword(value);
          }
      };

  return (
    <div className={classes.AuthContainer}>
      <h1 className="">Sign In</h1>
      <div className="">
        {error !== null && <div className = {classes.ErrorMessage}>{error}</div>}
        <form className={classes.AuthForm}>
          <label htmlFor="userEmail" className="">
            Email
          </label>
          <input
            type="email"
            className=""
            name="userEmail"
            value = {email}
            placeholder="Your Email"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="">
            Password
          </label>
          <input
            type="password"
            className=""
            name="userPassword"
            value = {password}
            placeholder="Your Password"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />
          <button 
            className="" 
            onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
            Sign in
          </button>
        </form>

        {/* <p className="">OR</p>
        <button
          className="">
          Sign in with Google
        </button> */}
        <p className="">
          {/* Don't have an account?{" "}
          <Link to="/edit/sign-up" className={classes.AuthLink}>
            Sign Up
          </Link>{" "}
          <br />{" "} */}

          <Link to = "/edit/password-reset" className={classes.AuthLink}>
            Reset Password
          </Link>
        </p>
      </div>
    </div>
  );
};
export default SignIn;