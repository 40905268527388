import React from 'react';
import classes from './ReorderProjectsConfirmation.module.css';

function reorderProjectsConfirmation() {
  return (
    <div className={classes.ReorderProjectsConfirmation}>
        <p>Order of projects has been updated!</p>
        <button onClick={() => {window.location.href = "/edit/reorder-projects"}}>CONTINUE EDITING</button>
        <button onClick={() => {window.location.href = "/edit/"}}>EDIT MENU</button>
        <button onClick={() => {window.location.href = "/"}}>HOME PAGE</button>
    </div>
  );
}

export default reorderProjectsConfirmation;