import Firebase from 'firebase';

const firebaseConfig = {
    //ANH'S FIREBASE
    // apiKey: "AIzaSyDiLfy3Ez8J7X1SxSEuQVzQDDq4wrPg_1o",
    // authDomain: "my-portfolio-905b4.firebaseapp.com",
    // databaseURL: "https://my-portfolio-905b4-default-rtdb.firebaseio.com",
    // projectId: "my-portfolio-905b4",
    // storageBucket: "my-portfolio-905b4.appspot.com",
    // messagingSenderId: "962819498220",
    // appId: "1:962819498220:web:77bd5a0c57bcdb3ecf45c9",
    // measurementId: "G-HSHNQR5K5T"

    //DESIGNINCUBATIONCENTRE'S FIREBASE
    // apiKey: "AIzaSyCIz6mFDc7nncZbXox7iEdowlKVuJBaVDM",
    // authDomain: "dic-website-508e7.firebaseapp.com",
    // databaseURL: "https://dic-website-508e7-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "dic-website-508e7",
    // storageBucket: "dic-website-508e7.appspot.com",
    // messagingSenderId: "1084886769815",
    // appId: "1:1084886769815:web:99baf0760098ea05c209d2",
    // measurementId: "G-M5L50R17D2"

    //NUSDICWEBSITE'S FIREBASE
    apiKey: "AIzaSyDahE_2s1vdojrc6g0yGxeOGwcu_uQ6Xzo",
    authDomain: "dic-website-b5ebd.firebaseapp.com",
    databaseURL: "https://dic-website-b5ebd-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "dic-website-b5ebd",
    storageBucket: "dic-website-b5ebd.appspot.com",
    messagingSenderId: "653161787468",
    appId: "1:653161787468:web:c354127d111365739144e9",
    measurementId: "G-V1D6Y64FNX"
};

Firebase.initializeApp(firebaseConfig);
// Firebase.analytics();
export const db = Firebase.database();
export const auth = Firebase.auth();
export const firestore = Firebase.firestore();
